@media only screen and (width <= 1024px) {
  .landing-page .div {
    height: 4500px;
  }

  .landing-page .footer {
    height: unset;
    margin-left: unset;
  }
}

@media only screen and (width <= 900px) {
  .landing-page .footer {
    flex-direction: column;
    gap: 20px;
  }

  .landing-page .social-buttons {
    margin-right: unset;
  }

  .landing-page .div {
    height: 4600px;
  }
}

@media only screen and (width <= 768px) {
  .landing-page .pricing-plans {
    height: 1000px;
  }

  .copyrightLinks {
    flex-direction: column;
  }
}
/*# sourceMappingURL=index.9c1688c3.css.map */
